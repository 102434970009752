body {
    color: black;
    width: 100%;
    position: relative;
    font-family: "Albert Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;
    padding: 0 24px;

    /* macOS and iOS */
    -webkit-font-smoothing: antialiased;

    /* Windows */
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-down(md) {
        padding: 0 16px;
    }

    h1 {
        font-size: calc(32px + (40 - 32) * ((100vw - 768px) / (1920 - 768)));
        font-weight: 400;
        margin-bottom: 0 !important;

        @media (min-width: 1400px) {
            font-size: 40px;
        }

        @media (max-width: 768px) {
            font-size: 32px;
        }
    }

    a {
        color: #000;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: #000;
        }
    }

    footer {
        margin-top: 200px;

        a {
            margin: 160px 0;
            text-transform: uppercase;
        }

        span {
            display: block;
            margin-bottom: 16px;
        }
    }
}