header{
    padding-top: 24px;

    @include media-breakpoint-down(md){
        padding-top: 16px;
    }

    .logo{
        font-size: 24px;
    }

    .status-pill{
        border-radius: 1000px;
        border: 1px solid #E9EEF4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        padding: 8px 12px 8px 12px;
        
        span.green{
            animation: pulse 1.6s infinite;
            background-color: #29C086;
            border-radius: 50%;
            height: 8px;
            margin-right: 10px;
            width: 8px;
        }

        @keyframes pulse {
            0% {
                opacity: 0.1;
            }

            50%{
                opacity: 1;
            }


            100% {
                opacity: 0.1;
            }
        }
    }

    nav{
        @include media-breakpoint-down(lg) {
            padding-top: 8px;
        }

        .links-container{
            .links-item{
                list-style: none;
                margin-right: 24px;

                &:last-child{
                    margin-right: 0;
                    
                }
                
                @include media-breakpoint-down(lg){
                    margin-right: 0;
                }

                .link{
                    color: #000;
                    display: block;
                    font-size: 16px;
                    text-decoration: none;
                    transition: color .2s linear;

                    @include media-breakpoint-down(lg){
                        margin-bottom: 8px;
                        text-align: right;
                    }

                    &:hover{
                        color: #6b7280;
                        transition: color .2s linear;
                    }
                }
            }
        }
    }
}