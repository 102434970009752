.intro{
    margin-bottom: 92px;
    padding-top: 170px;

    @include media-breakpoint-down(md){
        padding-top: 56px;
        margin-bottom: 64px;
    }

    .title{
        h1{
            text-transform: uppercase;
        }

        a{
            text-decoration: none;
            text-transform: uppercase;

            h1{
                position: relative;
                display: inline-block; /* Ensure the underline aligns with the h1 width */
            
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    background-color: #1f2937;
                    width: 0;
                    transition: width 0.4s ease, left 0.4s ease;
                }
            
                &:hover::after {
                    width: 100%;
                    left: 0; /* Ensures it animates from left to right */
                    transition: width 0.4s ease;
                }
            
                &:not(:hover)::after {
                    left: auto; /* Ensures it retracts from right to left */
                    right: 0;
                    width: 0;
                    transition: width 0.4s ease, right 0.4s ease;
                }
            }
        }
    }
}

