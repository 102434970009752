.pricing-container {
    margin-top: 200px;
    margin-bottom: 120px;

    @media ( max-width: 992px){
        flex-wrap: wrap;
        
        .d-grid{
            margin-bottom: 60px;
            min-width: 280px;
        }
    }

    .price-title {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 13px;

        @media (max-width: 992px){
            font-size: 30px;

            a{
                font-size: 24px;
            }
        }
    }

    p {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 40px;
    }

    ul {
        margin-bottom: 40px;
        
        li {
            list-style: none;

            .checkmark{
                margin-right: 14px;
            }
        }
    }

    @media (min-width: 992px ){
        .pl-20 {
            padding-left: 20px;
        }
        
        .pr-20 {
            padding-right: 20px;
        }
    }
}