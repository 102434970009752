.project-detail{
    padding-top: 99px;

    .title-row{
        margin-bottom: 243px;
    }

    .title-container{
        margin-bottom: 32px;
    }

    .title{
        h1{
            font-size: 64px;
            line-height: 64px;
            text-transform: uppercase;
            max-width: 900px;
        }

        p{
            margin-top: 32px;
        }
    }

    .tags{
        margin-left: 64px;
        
        span.tag{
            border: 1px solid #161A1E;
            border-radius: 45px;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-right: 8px;
            padding: 8px 12px;
            text-transform: uppercase;
        }
    }

    .gallery{
        gap:24px;

        img{
            height: auto;
            object-fit: cover;
            width: 49%;

            &:nth-child(3n){
                max-height: 1000px;
                width: 100%;
            }
        }
    }

    @media (max-width: 1250px) {
        .gallery {
          gap: 18px; /* Decrease gap to 18px */
        }
        .gallery img {
          width: calc(50% - 9px); /* Adjust width for new gap */
        }
      }
      
      @media (max-width: 1024px) {
        .gallery {
          gap: 12px; /* Decrease gap to 12px */
        }
        .gallery img {
          width: calc(50% - 6px); /* Adjust width for 12px gap */
        }
      }
      
      /* Set all images to full width at 998px and below */
      @media (max-width: 998px) {
        .gallery {
          gap: 12px; /* Maintain 12px gap for smaller screens */
        }
        .gallery img {
          width: 100%; /* Full width for all images */
        }
      }
}