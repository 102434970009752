.projects-container {
    column-count: 2; /* Number of columns */
    column-gap: 24px; /* Space between the columns */

    @include media-breakpoint-down(lg){
            column-count: 1;
    }

    .project-item {
        display: block;
        text-decoration: none;
        break-inside: avoid; 
        margin-bottom: 56px;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.7s ease-out, transform 0.7s ease-out;

        &.visible{
            opacity: 1;
            transform: translateY(0);
        }

        .img-container{
            margin-bottom: 16px;
            overflow: hidden;
            
            img {
                max-width: 100%;
                height: auto;
                display: block;
                transform: scale(1);
                transition: transform .3s linear;
            }
        }

        &:hover{
            img{
                transform: scale(1.03);
                transition: transform .3s linear;
            }
        }

        h2.title {
            font-size: 24px;
            text-transform: uppercase;
            margin-bottom: 0;
            font-weight: 400;
        }

        span {
            font-size: 16px;
        }
    }
}